<template>
  <div>
    <v-input :rules="rules" required v-model="valueLocal">
      <v-item-group :value="valueLocal" style="width:100%">
        <v-row class="my-2" justify="center">
          <div
            v-for="(item, index) in optionsToChoice"
            :key="index"
            class="my-1 px-2"
            style="width:100%"
          >
            <v-item :value="item.value" v-slot:default="{ active, toggle }">
              <v-card
                :color="active ? '#0080d1' : 'white'"
                :class="[
                  'pa-3 card-border-grey',
                  valid == false ? 'error--text' : '',
                ]"
                :dark="active"
                @click="toggle(), onAnswer(item.value), (valid = true)"
              >
                <v-row justify="space-between" align="center">
                  <v-col class="px-2 d-flex">
                    <span
                      class="custom-normal-text"
                      :style="active ? 'color:#ffffff' : 'color:#0080d1'"
                    >
                      {{ item.label }}
                      <base-bullet-info
                        v-if="item.info"
                        :href="item.info"
                      ></base-bullet-info>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-item>
          </div>
        </v-row>
      </v-item-group>
    </v-input>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: false,
    },

    optionsToChoice: {
      type: Array,
      required: true,
      default: () => [],
    },

    isValid: {
      type: Boolean,
      default: () => true,
    },

    rules: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      valid: this.isValid,
    }
  },

  computed: {
    valueLocal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    onAnswer(value) {
      this.$emit('onAnswer', value)
    },
  },

  watch: {
    isValid(newValue, oldValue) {
      this.valid = newValue
    },
  },
}
</script>
