<template>
  <div class="mx-3">
    <template v-if="isOpening">
      <h2 class="custom-title-secondary mb-1">Que ótimo!</h2>
      <p class="custom-normal-text">
        Aqui no Simplificador, nós não cobramos pela abertura da sua empresa e
        ainda te ajudamos em todas as etapas do processo.
        <br />
        Para isso, precisamos de mais algumas informações...
      </p>
      <p class="custom-normal-text text-green font-weight-bold">
        Você quer abrir essa empresa sozinho ou terá sócios?
      </p>
    </template>
    <template v-else>
      <h2 class="custom-title-secondary mb-1">
        Obrigado por confiar em nossa equipe!
      </h2>
      <p class="custom-normal-text">
        Estamos preparados para assumir a contabilidade da sua empresa.
      </p>
      <p class="custom-normal-text text-green font-weight-bold">
        Você tem sócios?
      </p>
    </template>
    <selectOptionsCard
      v-model="formUser.hasPartner"
      :optionsToChoice="choices"
      :isValid="isValid"
      @onAnswer="onAnswer($event)"
      :rules="[(v) => v == true || v == false || 'Selecione uma opcão']"
    ></selectOptionsCard>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import selectOptionsCard from '@components/registering/selectOptionsCard.vue'

export default {
  components: {
    selectOptionsCard,
  },
  props: {
    answer: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    ...mapGetters({
      isOpening: 'formDataUser/isOpening',
    }),
    questionTrue() {
      return this.isOpening ? 'Quero abrir uma empresa em sociedade' : 'Sim'
    },
    questionFalse() {
      return this.isOpening ? 'Serei somente eu' : 'Não'
    },
    choices() {
      return [
        {
          label: this.questionTrue,
          value: true,
        },
        {
          label: this.questionFalse,
          value: false,
        },
      ]
    },
  },
  data() {
    return {
      currentAnswer: this.answer,
    }
  },
  methods: {
    onAnswer(value) {
      this.formUser.hasPartner = value
      this.$emit('onAnswer', {
        hasPartner: this.formUser.hasPartner,
      })
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
